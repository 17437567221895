import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

export const charMaxWidth = '392px'

const ChartWrapper = styled.div`
  margin: 0 auto;
  max-width: ${charMaxWidth};
  overflow: hidden;
  padding-bottom: 80px;
  padding-top: 20px;
  width: 100%;
`

const InnerChart = styled.div(
  ({ hasSecondProduct, theme }) => css`
    align-items: flex-end;
    display: flex;
    height: ${hasSecondProduct ? `390px` : `320px`};
    justify-content: flex-end;
    position: relative;
    width: 100%;

    ${media.up('5xl')} {
      height: ${hasSecondProduct ? `416px` : `400px`};
    }

    &::before {
      background-color: ${selectTheme('colors.neutral.10')({ theme })};
      bottom: 0;
      content: '';
      height: 2px;
      position: absolute;
      right: 0;
      width: 100%;

      ${media.up('5xl')} {
        width: 97%;
      }
    }
  `,
)

export { ChartWrapper, InnerChart }
