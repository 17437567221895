import React from 'react'
import { bool, number, string } from 'prop-types'

import { CreditasLogo } from 'components/Icons'

import { Bar as BarElement, Logo, Number, Text } from './styles'

const propTypes = {
  animate: bool,
  hasSecondProduct: bool,
  percentage: number,
  percentageText: string,
  productName: string,
  showLogo: bool,
}

const defaultProps = {
  productName: '',
}

const Bar = props => {
  const {
    animate,
    hasSecondProduct,
    percentage,
    percentageText,
    productName,
    showLogo,
    ...others
  } = props

  return (
    <BarElement
      animate={animate}
      hasSecondProduct={hasSecondProduct}
      percentage={percentage}
    >
      <Number
        className="number"
        hasSecondProduct={hasSecondProduct}
        {...others}
      >
        {percentageText}
      </Number>
      <Text>{productName}</Text>
      {showLogo && (
        <Logo animate={animate} hasSecondProduct={hasSecondProduct}>
          <CreditasLogo />
        </Logo>
      )}
    </BarElement>
  )
}

Bar.propTypes = propTypes
Bar.defaultProps = defaultProps

export default Bar
