import { styled } from '@creditas-ui/system'
import { Typography as CreditasTypography } from '@creditas-ui/typography'
import { media } from '@creditas-ui/utilities'

const Typography = styled(CreditasTypography)`
  margin-bottom: 36px;

  ${media.up('5xl')} {
    margin-bottom: 64px;
  }
`

export { Typography }
