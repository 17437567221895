import { node } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  children: node,
}

const PreTitle = ({ children, ...others }) => {
  if (!children) return null
  return (
    <Typography
      color="neutral.80"
      variant={{ _: 'exceptionMdBold', '5xl': 'exceptionSmBold' }}
      {...others}
    >
      {children}
    </Typography>
  )
}

PreTitle.propTypes = propTypes

export default PreTitle
