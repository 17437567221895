import { styled } from '@creditas-ui/system'
import { getTypography, media } from '@creditas-ui/utilities'

import { ButtonCta as CreditasButtonCta } from 'components/ButtonCta'

const ButtonCta = styled(CreditasButtonCta)`
  &[class^='css-'] {
    border-radius: 10px;
    ${getTypography('bodySmMedium')}
    height: 60px;
    width: 100%;

    ${media.up('5xl')} {
      height: 44px;
      width: 223px;
    }
  }
`

export { ButtonCta }
