import { css, keyframes, styled } from '@creditas-ui/system'
import { getTypography, media, selectTheme } from '@creditas-ui/utilities'

import { charMaxWidth } from '../../styles'

const bar = keyframes`
  0% {
    height: 0;
    opacity: 0;
  }
  
  50% {
    opacity: 1;
  }
  
  100% {
    height: 400px;
  }
`

const fade = keyframes`
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
`

const fadeLogo = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(-50%, 10px, 0);
  }
  
  100% {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }
`

const maxPercentage = 339
const increaseHeight = '5px'

const animationControl = shouldAnimate => css`
  animation-play-state: ${shouldAnimate ? 'running' : 'paused'};
`
const barVariations = (theme, percentage, animate) => css`
  &:nth-of-type(3n - 2) {
    animation: ${bar} 4s ease-in-out both 1.9s;
    color: ${selectTheme('colors.primary.60')({ theme })};
    max-height: calc(
      (320px * ${percentage}) / ${maxPercentage} + ${increaseHeight}
    );

    &::after {
      height: calc(
        (320px * ${percentage}) / ${maxPercentage} + ${increaseHeight}
      );
    }

    ${media.up('5xl')} {
      max-height: calc(
        (400px * ${percentage}) / ${maxPercentage} + ${increaseHeight}
      );

      &::after {
        height: calc(
          (400px * ${percentage}) / ${maxPercentage} + ${increaseHeight}
        );
      }
    }

    .number {
      animation: ${fade} 0.5s ease-in-out both 2s;
      ${animationControl(animate)};
      font-weight: ${selectTheme('fontWeight.lg')({ theme })};
      left: -90%;
      padding-right: 4px;

      ${media.down('5xl')} {
        ${getTypography('bodyXsMedium')({ theme })};
      }
    }
  }

  &:nth-of-type(3n - 1) {
    color: ${selectTheme('colors.neutral.40')({ theme })};
    max-height: calc((320px * 90) / ${maxPercentage});

    &::after {
      height: calc((320px * 90) / ${maxPercentage});
    }

    ${media.up('5xl')} {
      max-height: calc((400px * 90) / ${maxPercentage});

      &::after {
        height: calc((400px * 90) / ${maxPercentage});
      }
    }

    .number {
      animation: ${fade} 0.5s ease-in-out 0.5s both;
      ${animationControl(animate)};
      color: ${selectTheme('colors.neutral.80')({ theme })};
      font-weight: ${selectTheme('fontWeight.md')({ theme })};
      left: calc(-190% - 4px);

      ${media.down('5xl')} {
        ${getTypography('bodyXsMedium')({ theme })};
      }

      &::after {
        border-bottom-color: ${selectTheme('colors.neutral.15')({ theme })};
      }
    }
  }

  &:nth-of-type(3n) {
    color: ${selectTheme('colors.neutral.80')({ theme })};
    max-height: 100%;

    &::after {
      height: 100%;
    }

    ${media.up('5xl')} {
      max-height: calc((400px * 339) / ${maxPercentage});

      &::after {
        height: calc((400px * 339) / ${maxPercentage});
      }
    }

    .number {
      animation: ${fade} 0.5s ease-in-out 1.5s both;
      ${animationControl(animate)};
      color: ${selectTheme('colors.neutral.80')({ theme })};
      font-weight: ${selectTheme('fontWeight.md')({ theme })};
      left: calc(-290% - 8px);

      ${media.down('5xl')} {
        ${getTypography('bodyXsMedium')({ theme })};
      }

      &::after {
        border-bottom-color: ${selectTheme('colors.neutral.15')({ theme })};
      }
    }
  }
`

const barWithSecondProductVariations = (theme, percentage, animate) => css`
  &:nth-of-type(4n - 3) {
    animation: ${bar} 4s ease-in-out both 1.9s;
    color: ${selectTheme('colors.primary.60')({ theme })};
    max-height: calc(
      (320px * ${percentage}) / ${maxPercentage} + ${increaseHeight}
    );

    ${media.up('5xl')} {
      max-height: calc(
        (400px * ${percentage}) / ${maxPercentage} + ${increaseHeight}
      );
    }

    .number {
      animation: ${fade} 0.5s ease-in-out both 2s;
      ${animationControl(animate)};
      font-weight: ${selectTheme('fontWeight.lg')({ theme })};
      bottom: calc(150% + 8px);
      width: 100%;
      text-align: center;

      ${media.down('5xl')} {
        ${getTypography('bodyXsMedium')({ theme })};
      }
    }
  }

  &:nth-of-type(4n - 2) {
    animation: ${bar} 4s ease-in-out both 1.9s;
    color: ${selectTheme('colors.primary.60')({ theme })};
    max-height: calc(
      (320px * ${percentage}) / ${maxPercentage} + ${increaseHeight}
    );

    ${media.up('5xl')} {
      max-height: calc(
        (400px * ${percentage}) / ${maxPercentage} + ${increaseHeight}
      );
    }

    .number {
      animation: ${fade} 0.5s ease-in-out both 2s;
      ${animationControl(animate)};
      font-weight: ${selectTheme('fontWeight.lg')({ theme })};
      bottom: calc(150% + 8px);
      width: 100%;

      ${media.down('5xl')} {
        ${getTypography('bodyXsMedium')({ theme })};
      }
    }
  }

  &:nth-of-type(4n - 1) {
    color: ${selectTheme('colors.neutral.40')({ theme })};
    max-height: calc((320px * 90) / ${maxPercentage});

    ${media.up('5xl')} {
      max-height: calc((400px * 90) / ${maxPercentage});
    }

    .number {
      animation: ${fade} 0.5s ease-in-out 0.5s both;
      ${animationControl(animate)};
      color: ${selectTheme('colors.neutral.80')({ theme })};
      bottom: calc(100% + 16px);
      width: 100%;

      ${media.down('5xl')} {
        ${getTypography('bodyXsMedium')({ theme })};
      }
    }
  }

  &:nth-of-type(4n) {
    color: ${selectTheme('colors.neutral.80')({ theme })};
    max-height: ${maxPercentage}px;

    ${media.up('5xl')} {
      max-height: calc((400px * 320) / ${maxPercentage});
    }

    .number {
      animation: ${fade} 0.5s ease-in-out 1.5s both;
      ${animationControl(animate)};
      color: ${selectTheme('colors.neutral.80')({ theme })};
      bottom: calc(100% + 16px);
      width: 100%;

      ${media.down('5xl')} {
        padding-right: 8px ${getTypography('bodyXsMedium')({ theme })};
      }
    }
  }
`

const Bar = styled.div(
  ({ animate, hasSecondProduct, percentage, theme }) => css`
    animation: ${bar} 2s ease-in-out both;
    ${animationControl(animate)};
    background: transparent;
    position: relative;
    width: 25%;
    max-width: 95px;
    z-index: 1;

    + div {
      margin-left: 4px;
    }

    &::before {
      background: currentColor;
      border-radius: 2px 2px 0 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 2;
    }

    &::after {
      background: transparent;
      content: '';
      height: 5px;
      left: 100%;
      position: absolute;
      width: ${charMaxWidth};
      z-index: 2;
    }

    ${hasSecondProduct
      ? barWithSecondProductVariations(theme, percentage, animate)
      : barVariations(theme, percentage, animate)}
  `,
)

const Logo = styled.div(
  ({ animate, hasSecondProduct }) => css`
    animation: ${fadeLogo} 1.5s ease 2s both;
    ${animationControl(animate)};
    bottom: ${hasSecondProduct ? `calc(150% + 16px)` : `100%`};
    height: 17px;
    left: 50%;
    margin-bottom: 10px;
    position: absolute;
    transform: translate3d(-50%, 0, 0);
    width: 23px;
  `,
)

const Number = styled.div(
  ({ hasSecondProduct, theme }) => css`
    bottom: 100%;
    color: currentColor;
    ${getTypography('bodyMdMedium')({ theme })}
    padding-right: 12px;
    position: absolute;
    text-align: right;
    transform: translate3d(0, 50%, 0);
    z-index: 1;

    ${!hasSecondProduct &&
    `
        &::after {
        border-bottom: dashed 1px currentColor;
        content: '';
        height: 1px;
        left: 100%;
        position: absolute;
        top: 50%;
        transform: translate3d(0, calc(-50% + 1px), 0);
        width: ${charMaxWidth};
      }
    `}
  `,
)

const Text = styled.div(
  ({ neutral, theme }) => css`
    color: ${neutral
      ? selectTheme('colors.neutral.80')({ theme })
      : selectTheme('colors.neutral.90')({ theme })};
    ${getTypography('bodyXsMedium')({ theme })}
    left: 0;
    margin-top: 8px;
    position: absolute;
    top: 100%;
    width: 100%;

    ${media.up('5xl')} {
      width: 65%;
    }
  `,
)

export { Bar, Logo, Number, Text }
