import { useCallback, useRef } from 'react'
import { func, node } from 'prop-types'

import useTriggerOnScroll from 'utils/useTriggerOnScroll'

import { Container, Grid, Section } from './styles'

const propTypes = {
  children: node,
  setSeen: func,
}

const Wrapper = ({ children, setSeen, ...others }) => {
  const currentSection = useRef(null)

  const handleAnimate = useCallback(() => {
    setSeen(true)
  }, [setSeen])

  useTriggerOnScroll(currentSection, handleAnimate)

  return (
    <Section ref={currentSection} {...others}>
      <Container>
        <Grid>{children}</Grid>
      </Container>
    </Section>
  )
}

Wrapper.propTypes = propTypes

export default Wrapper
