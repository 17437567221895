import { useState } from 'react'
import { number, object, oneOf, string } from 'prop-types'

import { GridItem } from '@creditas/layout'
import { useTriggerEvents } from 'hooks/useTriggerEvents'

import Button from './components/Button'
import Chart from './components/Chart'
import PreTitle from './components/PreTitle'
import Title from './components/Title'
import Wrapper from './components/Wrapper'

const propTypes = {
  buttonCta: object,
  chartOurProductName: string,
  chartOurProductPercentage: number,
  chartOurSecondProductName: string,
  chartOurSecondProductPercentage: number,
  preTitle: string,
  preTitleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  title: string,
  titleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
}

const defaultProps = {
  chartOurProductPercentage: 0,
  chartOurSecondProductPercentage: 0,
  preTitleHeadingTag: 'h2',
  titleHeadingTag: 'h3',
}

const WhyChooseUsChart = props => {
  const {
    buttonCta,
    chartOurProductName,
    chartOurProductPercentage,
    chartOurSecondProductName,
    chartOurSecondProductPercentage,
    preTitle,
    preTitleHeadingTag,
    title,
    titleHeadingTag,
    ...others
  } = props

  const [isSeen, setSeen] = useState(false)
  const currentPreTitleHeadingTag =
    preTitleHeadingTag ?? defaultProps.preTitleHeadingTag
  const currentTitleHeadingTag = titleHeadingTag ?? defaultProps.titleHeadingTag

  const { triggerEvent } = useTriggerEvents()

  const handleClick = () => {
    if (!buttonCta?.label || !buttonCta?.actionType) return

    const isPageInteraction = buttonCta?.actionType !== 'link'
    triggerEvent({
      label: buttonCta?.label,
      pageInteraction: isPageInteraction,
    })
  }

  const hasSecondProduct =
    chartOurSecondProductName && chartOurSecondProductPercentage

  const secondProductProps = {
    hasSecondProduct,
    ourSecondProductName: chartOurSecondProductName,
    ourSecondProductPercentage: chartOurSecondProductPercentage,
  }

  const chartProps = {
    ourProductName: chartOurProductName,
    ourProductPercentage: chartOurProductPercentage,
    ...(hasSecondProduct ? { ...secondProductProps } : {}),
  }

  return (
    <Wrapper setSeen={setSeen} {...others}>
      <GridItem area="chart">
        <Chart animate={isSeen} {...chartProps} />
      </GridItem>
      <GridItem area="content">
        <PreTitle as={currentPreTitleHeadingTag}>{preTitle}</PreTitle>
        <Title as={currentTitleHeadingTag}>{title}</Title>
      </GridItem>
      <GridItem area="button">
        <Button
          buttonCta={buttonCta}
          onClick={handleClick}
          analyticEvent={false}
        />
      </GridItem>
    </Wrapper>
  )
}

WhyChooseUsChart.propTypes = propTypes
WhyChooseUsChart.defaultProps = defaultProps

export default WhyChooseUsChart
