import { useRef } from 'react'
import { bool, number, string } from 'prop-types'

import Bar from './components/Bar'

import { ChartWrapper, InnerChart } from './styles'

const propTypes = {
  animate: bool.isRequired,
  hasSecondProduct: bool,
  ourProductName: string,
  ourProductPercentage: number,
  ourSecondProductName: string,
  ourSecondProductPercentage: number,
}

const defaultProps = {
  hasSecondProduct: false,
  ourProductName: '',
  ourProductPercentage: 0,
  ourSecondProductName: '',
  ourSecondProductPercentage: 0,
}

const Chart = ({
  animate,
  hasSecondProduct,
  ourProductName,
  ourProductPercentage,
  ourSecondProductName,
  ourSecondProductPercentage,
}) => {
  const getPercentage = percentageNumber => parseFloat(percentageNumber ?? 0)

  const chart = useRef(null)

  const textContent = {
    percentageText: `${getPercentage(ourProductPercentage)}% a.a`,
    bar2PercentageText: '77% a.a',
    bar2Text: 'Empréstimo Pessoal',
    bar3Text: 'Cartão de Crédito Rotativo',
    bar3PercentageText: '339% a.a',
  }

  if (hasSecondProduct) {
    textContent.secondPercentageText = `${getPercentage(
      ourSecondProductPercentage,
    )}% a.a`
    textContent.ourSecondProductName = ourSecondProductName
  }

  return (
    <ChartWrapper data-testid="why-choose-us-chart" ref={chart}>
      <InnerChart hasSecondProduct={hasSecondProduct}>
        <Bar
          percentage={getPercentage(ourProductPercentage)}
          percentageText={textContent.percentageText}
          productName={ourProductName}
          hasSecondProduct={hasSecondProduct}
          animate={animate}
          showLogo
          data-testid="why-choose-us-number"
        />
        {hasSecondProduct && (
          <Bar
            percentage={getPercentage(ourSecondProductPercentage)}
            percentageText={textContent.secondPercentageText}
            productName={ourSecondProductName}
            hasSecondProduct={hasSecondProduct}
            animate={animate}
            showLogo
            data-testid="why-choose-us-second-number"
          />
        )}
        <Bar
          percentageText={textContent.bar2PercentageText}
          productName={textContent.bar2Text}
          hasSecondProduct={hasSecondProduct}
          animate={animate}
        />
        <Bar
          percentageText={textContent.bar3PercentageText}
          productName={textContent.bar3Text}
          hasSecondProduct={hasSecondProduct}
          animate={animate}
        />
      </InnerChart>
    </ChartWrapper>
  )
}

Chart.propTypes = propTypes
Chart.defaultProps = defaultProps

export default Chart
