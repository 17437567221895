import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as CreditasContainer } from 'components/Container'
import { Grid as CreditasGrid } from 'components/Grid'

const Section = styled.section`
  background: ${selectTheme('colors.neutral.0')};
  display: inline-block;
  height: 100%;
  overflow: visible;
  width: 100%;
`

const Container = styled(CreditasContainer)`
  padding: 64px 16px 72px;

  ${media.up('4xl')} {
    padding: 120px 16px;
  }
`

const Grid = styled(CreditasGrid)`
  grid-template-rows: max-content;
  grid-template-areas:
    '${'content '.repeat(4)}'
    '${'chart '.repeat(4)}'
    '${'button '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas:
      '${'content '.repeat(8)}'
      '${'chart '.repeat(8)}'
      '${'button '.repeat(8)}';
  }

  ${media.up('5xl')} {
    grid-template-areas:
      '${'chart '.repeat(6)} ${'content '.repeat(6)}'
      '${'chart '.repeat(6)} ${'button '.repeat(6)}';
  }
`

export { Container, Grid, Section }
