import { object } from 'prop-types'

import { ArrowRightIcon } from 'components/Icons'

import { ButtonCta } from './styles'

const propTypes = {
  buttonCta: object,
}

const Button = ({ buttonCta }) => {
  if (!buttonCta) return null
  const { id } = buttonCta
  return (
    <ButtonCta key={id} data-testid="button-cta" {...buttonCta}>
      <ArrowRightIcon />
    </ButtonCta>
  )
}

Button.propTypes = propTypes

export default Button
